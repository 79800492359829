import { useState, useRef } from "react";
import { motion } from "framer-motion";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import SPKL_LOGO from "./spkl_logo.svg";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";

const AnimatedDot = ({ delay }) => {
  const positionRef = useRef({
    left: `${Math.random() * 100}%`,
    top: `${Math.random() * 100}%`,
  });

  return (
    <motion.div
      className="absolute w-1 h-1 bg-white rounded-full"
      animate={{
        scale: [1, 1.5, 1],
        opacity: [0.7, 1, 0.7],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        delay: delay,
      }}
      style={positionRef.current}
    />
  );
};

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const loginSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const user = userCredential.user;
      console.log("Login successful", user);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error.message);
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen overflow-hidden bg-black">
      {/* Animated background */}
      {Array.from({ length: 50 }).map((_, i) => (
        <AnimatedDot key={i} delay={Math.random() * 2} />
      ))}
      {/* Sign-in form */}
      <div className="relative z-10 w-full max-w-md p-6 bg-white rounded-lg backdrop-blur-lg shadow-xl">
        <img
          src={SPKL_LOGO}
          className="h-16 w-16 mx-auto mb-4"
          alt="Spockal Logo"
        />
        <h2 className="mb-6 text-3xl font-bold text-center text-black">
          Delivery Login
        </h2>
        <form onSubmit={loginSubmitHandler} className="space-y-4">
          <div>
            <Label htmlFor="username" className="text-black">
              Username
            </Label>
            <Input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 bg-white bg-opacity-20 border-gray-600 text-black placeholder-gray-400"
              placeholder="Enter your username"
              required
            />
          </div>
          <div>
            <Label htmlFor="password" className="text-black">
              Password
            </Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 bg-white bg-opacity-20 border-gray-600 text-black placeholder-gray-400"
              placeholder="Enter your password"
              required
            />
          </div>
          <Button type="submit" className="w-full hover:bg-black text-white">
            Sign In
          </Button>
        </form>
        <p className="mt-3">
          Use of this site is bound by the delivery terms of service.
        </p>
      </div>
    </div>
  );
};

export default Login;
