import { Button } from "@/components/ui/button";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Make sure this path is correct
import { onAuthStateChanged, signOut } from "firebase/auth";

import SPKL_LOGO from "spkl_logo.svg";

const AdminNav = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <header className="w-full bg-white dark:bg-gray-950">
      <div className="flex items-center justify-between h-20 px-4 md:px-6">
        <div className="flex items-center flex-grow">
          <Link to="/" className="flex items-center gap-3" prefetch={false}>
            <img className="w-10 h-10" src={SPKL_LOGO} alt="Spockal Logo" />
            <span className="font-bold text-2xl hidden sm:block">Spockal</span>
          </Link>
          {isLoggedIn && (
            <div className="m-3">
              <Link
                to="/dashboard"
                className="inline-flex items-center gap-3 text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
                prefetch={false}
              >
                Dashboard
              </Link>{" "}
            </div>
          )}
        </div>
        <div className="flex gap-6">
          {isLoggedIn ? (
            <div>
              <Button
                onClick={handleLogout}
                className="text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
              >
                Logout
              </Button>
            </div>
          ) : (
            <>
              <Link
                to="/login"
                className="text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
                prefetch={false}
              >
                Login
              </Link>
            </>
          )}
        </div>
      </div>
      <hr />
    </header>
  );
};

export default AdminNav;
