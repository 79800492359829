import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./YComponents/dashboard";
import Login from "./Login";
import ProtectedRoute from "./protectedRoute";
import { auth } from "./firebase"; // Assuming you have firebase set up for authentication
import AdminNav from "navigation/adminNav";
import Home from "Home";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if the user is authenticated
    const unsubscribe = auth.onAuthStateChanged(() => {
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Optional: Add a loading spinner
  }

  return (
    <div>
      <Router>
        <AdminNav />
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
