import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="min-h-screen bg-white bg-dotted-pattern">
      <div className="pt-5 sm:pt-10 px-4 sm:px-10 text-center">
        <div className="text-left text-7xl sm:text-7xl md:text-8xl lg:text-9xl font-bold inline-block bg-white p-3 sm:p-5">
          <span className="block">Fast</span>
          <span className="block">Reliable</span>
          <span className="block">Delivery</span>
          <span className="block text-cyan-800">- Spockal</span>

          {/* <div className="flex space-x-4 mt-8">
            <Button className="text-2xl py-6 px-10">Join</Button>
            <Button className="text-2xl py-6 px-10">
              <Link to="/auth/storelogin">Login</Link>
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
