import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { PackageIcon, TruckIcon } from "lucide-react";
import axios from "axios";
import { Separator } from "@/components/ui/separator";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import "./dashboard.css";

export default function Dashboard() {
  const [isDelivery, setIsDelivery] = useState(true);
  const [orders, setOrders] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [slideDirection, setSlideDirection] = useState("");
  useEffect(() => {
    setSlideDirection(isDelivery ? "slide-left" : "slide-right");
  }, [isDelivery]);
  const handleDeleteClick = (order) => {
    setOrderToDelete(order);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (confirmationInput.toLowerCase() === "admin") {
      try {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/orders/${orderToDelete.id}/delete`
        );
        setOrders(orders.filter((order) => order._id !== orderToDelete.id));
        setIsDeleteDialogOpen(false);
        setOrderToDelete(null);
        setConfirmationInput("");
        fetchOrders();
      } catch (error) {
        console.error("Error deleting order:", error);
        alert("Failed to delete order. Please try again.");
      }
    } else {
      alert("Incorrect confirmation. Please type 'Admin' to confirm deletion.");
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/orders/adminorders`
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const openMaps = (address) => {
    // Check if the device is iOS
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Encode the address for use in a URL
    const encodedAddress = encodeURIComponent(address);

    // Construct the appropriate URL
    const mapsUrl = isIOS
      ? `maps://maps.apple.com/?q=${encodedAddress}`
      : `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

    // Open the URL in a new window/tab
    window.open(mapsUrl, "_blank");
  };
  const updateOrderStatus = async (orderId, status) => {
    console.log(orderId);
    try {
      if (status === "done") {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/moveToDone`
        );
      } else {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/orders/${orderId}/status`,
          { orderStatus: status }
        );
      }
      fetchOrders(); // Refresh orders after update
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-2 text-center">Welcome Admin</h1>
      <h2 className="text-xl font-semibold mb-4 text-center">
        Orders Dashboard
      </h2>

      {/* Custom Toggle UI */}
      <div className="flex justify-center items-center mb-4">
        <ToggleGroup
          type="single"
          value={isDelivery ? "delivery" : "pickup"}
          onValueChange={(value) => setIsDelivery(value === "delivery")}
          className="rounded-full border border-gray-300 shadow-sm"
        >
          <ToggleGroupItem
            value="delivery"
            className="rounded-l-full px-6 py-2 text-sm font-medium data-[state=on]:bg-white data-[state=off]:bg-gray-200 transition-colors duration-300"
          >
            Delivery Orders
          </ToggleGroupItem>
          <ToggleGroupItem
            value="pickup"
            className="rounded-r-full px-6 py-2 text-sm font-medium data-[state=on]:bg-white data-[state=off]:bg-gray-200 transition-colors duration-300"
          >
            Pickup Orders
          </ToggleGroupItem>
        </ToggleGroup>
      </div>

      <div className="space-y-4">
        {orders.filter((order) => order.isDelivery === isDelivery).length ===
        0 ? (
          <div className="flex flex-col items-center justify-center h-[50vh] gap-6">
            <div className="bg-muted/50 p-6 rounded-lg">
              <PackageIcon className="w-12 h-12 text-muted-foreground" />
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-bold">No orders yet</h3>
              <p className="text-muted-foreground">Make dua we get some</p>
            </div>
          </div>
        ) : (
          orders
            .filter((order) => order.isDelivery === isDelivery)
            .map((order) => (
              <div key={order.id} className="border rounded-lg p-4">
                <div key={order.id} className="border rounded-lg p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold">{order.storeName}</h3>
                    <span className="text-sm text-gray-500">
                      {order.deliveryTime}
                    </span>
                  </div>
                  <div>
                    {isDelivery && (
                      <Button
                        className="m-1"
                        onClick={() => openMaps(order.address)}
                      >
                        Take Me There
                      </Button>
                    )}
                    <Button
                      onClick={() => handleDeleteClick(order)}
                      variant="outline"
                      className="text-red-500 border-red-500 hover:bg-red-100 hover:text-red-500"
                    >
                      Delete Order
                    </Button>
                  </div>
                  <Separator className="mt-2" />
                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="item-1">
                      <AccordionTrigger>Important Info</AccordionTrigger>
                      <AccordionContent>
                        <p>
                          <strong>Name:</strong> {order.name}
                        </p>
                        <p>
                          <strong>Number of Items:</strong> {order.items}
                        </p>
                        <p>
                          <strong>Delivery Address:</strong> {order.address}
                        </p>
                        <p>
                          <strong>Charged Total:</strong> ${order.totalcost}
                        </p>
                      </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-2">
                      <AccordionTrigger>Products</AccordionTrigger>
                      <AccordionContent>
                        <Table>
                          <TableHeader>
                            <TableRow>
                              <TableHead>Product</TableHead>
                              <TableHead>Quantity</TableHead>
                              <TableHead>Price</TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {order.products.map((product, index) => (
                              <TableRow key={index}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>{product.quantity}</TableCell>
                                <TableCell>${product.price}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-3">
                      <AccordionTrigger>User</AccordionTrigger>
                      <AccordionContent>
                        <p>
                          <strong>First Name:</strong> {order.user.firstName}
                        </p>
                        <p>
                          <strong>Last Name:</strong> {order.user.lastName}
                        </p>
                        <p>
                          <strong>Email:</strong> {order.user.email}
                        </p>
                        <p>
                          <strong>Phone:</strong> {order.user.phone}
                        </p>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>

                  <div className="mt-4 flex justify-between">
                    {["received", "accepted", "ready", "done"].map((status) => (
                      <Button
                        key={status}
                        variant={
                          order.orderStatus === status ? "default" : "outline"
                        }
                        onClick={() => updateOrderStatus(order.id, status)}
                      >
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            ))
        )}
      </div>
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Order Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this order? This action cannot be
              undone. Please type "Admin" to confirm.
            </DialogDescription>
          </DialogHeader>
          <Input
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.target.value)}
            placeholder="Type 'Admin' to confirm"
          />
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm}>Delete</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
